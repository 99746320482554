.authentication {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: transparent url(../../images/justin-wang-7e9TPmPWWjs-unsplash.jpg)
    center no-repeat;
  background-size: 100% 100%;
  height: 654px;
}

.authentication__form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  gap: 20px;
}
.authentication__input {
  color: black;
  height: 40px;
  width: 200px;
  background-color: transparent;
  border-style: dotted;
  text-indent: 5px;
  border-top: none;
  border-left: none;
  border-right: none;
}

.authentication__submit {
  height: 40px;
  width: 115px;
  border-radius: 10px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.authentication__submit:hover {
  opacity: 0.2;
}
