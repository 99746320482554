.app {
  text-align: center;
  background-color: black;
  height: 100%;
}

.app__loggedin {
  background: transparent center
    url(../../images/pierre-borthiry-peiobty-vBCVcWUyvyM-unsplash.jpg) no-repeat;
  background-size: 100% 100%;
}
