.main {
  color: white;
  padding-top: 30px;
  padding: 30px 25px;
  height: 100%;
}

.main__header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.main__buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;
}

.main__button {
  width: 150px;
  height: 40px;
  background-color: white;
  border-radius: 10px;
  cursor: pointer;
}

.main__button:disabled {
  opacity: 0.2;
}

.main__logout {
  margin-left: auto;
}

.main__button:hover {
  opacity: 0.2;
}

.main__googlesheet-none {
  display: none;
}

.main__googlesheet {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 30px;
}

.main__googlesheet-url {
  color: blue;
}

.main__info {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 30px;
  gap: 25px;
}

.main__info-group {
  font-size: larger;
  font-weight: 900;
  display: flex;
  flex-direction: column;
}
