.header {
  height: 100px;
  color: red;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-weight: bold;
  font-size: larger;
  line-height: normal;
  margin-top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header__title {
  margin: 0;
}
